@media (max-width: 768px) {
  .ant-page-header-heading-left {
    flex-direction: column;
  }
  .ant-page-header-heading-title {
    white-space: normal !important;
    text-align: center;
  }
  .ant-page-header-heading-extra {
    display: flex;
    align-items: baseline;
    text-align: center;
  }
}